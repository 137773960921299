@import "../../../Assets/Theme/_var.scss";

.custom-table {
  &__title {
    font-size: 3rem;
    padding-bottom: 2.5rem;
    margin-bottom: 2.5rem;
    min-width: 30%;
    @media (max-width: 1679px) {
      font-size: 2.4rem;
      padding-bottom: 2.5rem;
      margin-bottom: 2rem;
    }
    @media (max-width: 1368px) {
      font-size: 2rem;
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
    }
    @media (max-width: 575px) {
      font-size: 1.8rem;
    }
  }
  &__table {
    &.table {
      --bs-table-bg: transparent;
      white-space: nowrap;

      thead {
        border: none;

        tr {
          th {
            font-size: 1.6rem;
            padding: 1rem 2rem;
            font-weight: 500;
            border: none;
            color: $secondary4;

            @media (max-width: 1679px) {
              font-size: 1.4rem;
            }
            @media (max-width: 575px) {
              font-size: 1.2rem;
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 0.1rem solid $borderClr2;
        }
        td {
          white-space: nowrap;
          padding: 1.9rem 2rem;
          font-size: 2rem;
          vertical-align: middle;
          border-bottom: 0;
          min-width: 18rem;
          color: $white;

          @media (max-width: 1679px) {
            padding: 1.5rem 2rem;
            font-size: 1.8rem;
          }

          @media (max-width: 1368px) {
            padding: 1rem 2rem;
            font-size: 1.6rem;
          }
          @media (max-width: 575px) {
            font-size: 1.4rem;
          }

          // status classes

          &.completed {
            color: #59ac0f;
          }
          &.pending {
            color: #fde076;
          }
          &.cancel {
            color: #c3292d;
          }
        }

        tr {
          &.no_record {
            td {
              padding: 1.5rem 0;
              .no_record_box {
                border-radius: 1.6rem;
                padding: 6rem 2rem;
                background: $gradient2;

                h4 {
                  font-size: 2rem;
                  font-weight: 400;
                  margin-top: 1.5rem;

                  @media (max-width: 1368px) {
                    font-size: 1.6rem;
                  }
                }
                @media (max-width: 991px) {
                  padding: 4rem 2rem;
                }
              }
            }
          }
        }
      }

      th,
      td {
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      .token-icon {
        display: inline-table;
        width: 3.6rem;
        height: 3.6rem;
        margin-right: 1rem;
        border-radius: 50%;
        overflow: hidden;

        @media (max-width: 1368px) {
          width: 3rem;
          height: 3rem;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &__pagination {
    padding-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $secondary3;

    @media (max-width: 767px) {
      padding-top: 1.5rem;
      flex-direction: column-reverse;
      gap: 1.5rem;
    }
  }
}

.common_table {
  color: transparent;
}
