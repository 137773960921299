@import "../../../Assets/Theme/_var.scss";

.sidebar {
  background: $secondary2;
  position: fixed;
  max-width: 37.5rem;
  width: 100%;
  left: 0;
  top: 0;
  height: 100vh;
  overflow: auto;
  border-right: 0.1rem solid rgba($secondary3, 0.2);

  @media (max-width: 1679px) {
    max-width: 32rem;
  }
  @media (max-width: 1439px) {
    max-width: 28rem;
  }
  &__logo {
    padding: 2.4rem;
    display: block;
    border-bottom: 0.1rem solid rgba($secondary3, 0.2);
    @media (max-width: 1199px) {
      padding: 1.8rem;
    }

    img {
      @media (max-width: 1199px) {
        max-width: 22rem;
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    height: calc(100% - 9.5rem);

    @media (max-width: 1199px) {
      height: calc(100% - 7.5rem);
    }

    ul {
      padding: 2.4rem;
      @media (max-width: 1199px) {
        padding: 1.8rem;
      }

      li {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        a {
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 2.4rem;
          letter-spacing: -0.01em;
          color: $white;
          padding: 1.2rem;

          border-radius: 1rem;
          display: flex;
          align-items: center;
          position: relative;
          z-index: 1;
          transition: 200ms ease-in-out;

          &::after {
            content: "";
            width: 100%;
            height: 100%;
            border-radius: inherit;
            position: absolute;
            top: 50%;

            left: 50%;
            transform: translate(-50%, -50%);
            background: $gradient;
            z-index: -1;
            opacity: 0;
            transition: opacity 200ms ease-in-out;
          }

          span {
            display: inline-flex;
            width: 1.8rem;
            height: 1.8rem;
            flex-shrink: 0;
            margin-right: 1rem;
            // transform: translateY(-0.1rem);

            svg {
              width: 100%;
              height: 100%;
              path {
                transition: 200ms ease-in-out;
              }
            }
          }

          @media (max-width: 1199px) {
            font-size: 1.6rem;
            padding: 1.4rem 1.6rem;
            font-weight: 500;

            span {
              width: 1.8rem;
              height: 1.8rem;
            }
          }

          &.active,
          &:hover {
            color: $white;

            &::after {
              opacity: 1;
            }

            svg {
              path {
                // fill: $white;
              }
            }
          }

          &.not_fill {
            &.active,
            &:hover {
              svg {
                path {
                  fill: none;
                  stroke: $white;
                }
              }
            }
          }
        }
      }
    }
    &__bottom {
      padding: 2.4rem;
      margin-top: auto;
      border-top: 0.1rem solid rgba($secondary3, 0.2);
      @media (max-width: 1199px) {
        padding: 1.8rem;
      }
    }
  }

  @media (min-width: 1200px) {
    transform: none !important;
  }

  @media (max-width: 1199px) {
    transform: translateX(-100%);
    z-index: $sidebar;
    transition: 200ms ease-in-out;
    background: transparent;
    border-right: none;
    max-width: 35rem;

    &.active {
      transform: none;
    }

    .token_icon {
      max-width: 10rem;
    }
  }

  @media (max-width: 575px) {
    max-width: 100%;
  }
}

.sidebar_bg {
  background: $secondary2;
  height: 100%;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: $sidebar - 1;
}

.overlay {
  position: fixed;
  top: 0;
  z-index: $sidebar_overlay;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($black, 0.3);
  transition: 200ms ease-in-out;
  backdrop-filter: blur(1rem);
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}
