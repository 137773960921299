@import "../../../Assets/Theme/_var.scss";

.overlayloader {
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  margin-top: -1px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 9991;
  height: 102%;
  background-color: #2a2e2fcc;

  &__inner {
    margin-right: -5.5rem;
  }
}
