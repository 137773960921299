@import "../../../Assets/Theme/_var.scss";

.common-heading {
  font-weight: 700;
  position: relative;
  padding-bottom: 2rem;
  margin-bottom: 6rem;
  display: inline-block;
  @media (max-width: 1679px) {
    padding-bottom: 1.5rem;
    margin-bottom: 4rem;
    font-size: 3.2rem;
  }
  @media (max-width: 991px) {
    margin-bottom: 4rem;
    font-size: 2.8rem;
  }
  @media (max-width: 575px) {
    font-size: 2rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.1rem;
    background: $gradient3;
    left: 0;
    bottom: 0;
  }

  &.mb40 {
    margin-bottom: 4rem;
    @media (max-width: 575px) {
      margin-bottom: 2rem;
    }
  }
}
