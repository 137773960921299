@import "../../../Assets/Theme/_var.scss";

.search-field {
  &__search {
    display: flex;
    align-items: center;
    gap: 1rem;
    input {
      border: 0;
      background: linear-gradient(
        95.8deg,
        #292c2e 4.61%,
        rgba(32, 39, 44, 1) 128.29%
      );
      color: $white;
      font-size: 1.6rem;
      padding: 1rem 2rem;
      border-radius: 3.6rem;
      min-width: 38rem;

      &:focus {
        color: $white;

        box-shadow: unset;
      }

      &::placeholder {
        color: rgba($white, 0.4);
      }
    }

    button {
      background: $btnGradient;
      width: 4.4rem;
      height: 4.4rem;
      flex-shrink: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }
}
