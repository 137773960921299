@import "~bootstrap/scss/bootstrap";
@import "./Assets/Theme/_var.scss";

html {
  font-size: 62.5%;
  overflow-x: clip;
}

body {
  margin: 0;
  font-family: $baseFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  background-color: #020713;
  // background-image: url("./Assets/Images/main_bg.jpg");
  background-repeat: no-repeat;
  color: $white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  padding-left: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

span,
a {
  display: inline-block;
}
img,
svg,
video {
  max-width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

button {
  background-color: transparent;
  border: 0;
  padding: 0;
}

h1,
.h1 {
  font-size: 7rem;
  font-weight: 700;

  @media (max-width: 1679px) {
    font-size: 5rem;
  }

  @media (max-width: 1199px) {
    font-size: 4rem;
  }
  @media (max-width: 575px) {
    font-size: 3rem;
  }
}

h2,
.h2 {
  font-size: 4.8rem;

  @media (max-width: 1679px) {
    font-size: 4rem;
  }

  @media (max-width: 1439px) {
    font-size: 3rem;
  }
  @media (max-width: 575px) {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: 3.8rem;
  @media (max-width: 1679px) {
    font-size: 3rem;
  }
  @media (max-width: 1439px) {
    font-size: 2.4rem;
  }
  @media (max-width: 767px) {
    font-size: 1.8rem;
  }
}

h4,
.h4 {
  font-size: 2.8rem;
  @media (max-width: 1679px) {
    font-size: 2.2rem;
  }
  @media (max-width: 1439px) {
    font-size: 1.8rem;
  }
}

h5,
.h5 {
  font-size: 2.4rem;
  @media (max-width: 1679px) {
    font-size: 1.8rem;
  }

  @media (max-width: 1439px) {
    font-size: 1.6rem;
  }
}

h6,
.h6 {
  font-size: 1.8rem;

  @media (max-width: 1679px) {
    font-size: 1.6rem;
  }

  @media (max-width: 991px) {
    font-size: 1.4rem;
  }
}

p,
.p {
  font-size: 1.6rem;

  @media (max-width: 1679px) {
    font-size: 1.4rem;
  }
  @media (max-width: 767px) {
    font-size: 1.2rem;
  }
  // @media (max-width: 575px) {
  //   font-size: 1rem;
  // }
}

.cursor-pointer {
  cursor: pointer !important;
}

.container {
  padding: 0 1.5rem;
  max-width: 1400px;

  // @media (max-width: 1679px) {
  //   max-width: 1230px;
  // }
}

.text-gradient {
  background: $gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-orange {
  color: $primary2 !important;
}

// slick slider style

.slick-slider {
  // arrow style

  .slick-arrow {
    opacity: 1;
    &.slick-disabled {
      opacity: 0.5;
    }
    &.slick-prev {
      left: -1rem;
    }
    &.slick-next {
      right: -1rem;
    }
    &::before {
      content: none;
    }
  }

  // dots style
  .slick-dots {
    li {
      width: 10px;
      height: 10px;
      margin: 0 0.3rem;
      button {
        padding: 0;
        width: 10px;
        height: 10px;
        background: rgba(111, 114, 116, 0.3);
        border-radius: 50%;
        &::before {
          content: none;
        }
      }

      &.slick-active {
        button {
          background: $btnGradient;
        }
      }
    }
  }
}

.or-text {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 2rem;
  position: relative;

  @media (max-width: 1679px) {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 767px) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  &::after {
    content: "";
    position: absolute;
    width: 30%;
    height: 0.1rem;
    background: $gradient3;
    top: 50%;
    left: 2.5rem;
  }
}

.mob-hide {
  display: none;
  @media (min-width: 767px) {
    display: block;
  }
}

.show-more {
  font-size: 1.2rem;
  display: inline-block;
  @media (min-width: 767px) {
    display: none;
  }

  strong {
    color: $secondary;
  }
}

.mob-content {
  p {
    margin-bottom: 0 !important;
  }

  @media (max-width: 767px) {
    margin-bottom: 4rem;
  }
}

.common-bg {
  border-radius: 2.7rem;
  background: linear-gradient(
    259.94deg,
    #212122 2.83%,
    rgba(0, 0, 0, 1) 294.13%
  );
  position: relative;

  @media (max-width: 1368px) {
    border-radius: 2.2rem;
  }
  @media (max-width: 767px) {
    border-radius: 1.8rem;
  }

  &::before,
  &:after {
    content: "";
    position: absolute;
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
    height: 100%;
    background: linear-gradient(180deg, #414343 0%, rgba(45, 44, 44, 1) 65.21%);
    border-radius: 2.7rem;
    z-index: -2;
    @media (max-width: 1368px) {
      border-radius: 2.2rem;
    }
    @media (max-width: 767px) {
      border-radius: 1.8rem;
    }
  }
  &:after {
    width: 100%;
    background-color: $black;
    z-index: -1;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #000000 inset !important;
  caret-color: white !important;
  transition: background-color 5000s !important;
  -webkit-text-fill-color: #fff !important;
  // border-radius: 5px !important;
}

// nav style

.nav-style {
  border-bottom: 0.1rem solid $borderClr;
  .nav-item {
    &:not(:last-child) {
      margin-right: 3.5rem;

      @media (max-width: 1368px) {
        margin-right: 2.5rem;
      }

      @media (max-width: 767px) {
        margin-right: 1.5rem;
      }
    }
  }

  .nav-link {
    font-size: 1.8rem;
    font-weight: 700;
    padding: 0.5rem 0.5rem 1.75rem;
    color: $white;
    position: relative;

    @media (max-width: 1368px) {
      // font-size: 1.6rem;
      padding: 0.5rem 0.5rem 1.25rem;
    }

    @media (max-width: 767px) {
      font-size: 1.6rem;
    }

    &::after {
      content: none;
      display: inline-block;
      position: absolute;
      bottom: -0.1rem;
      left: 0;
      width: 100%;
      height: 0.2rem;
      background: $gradient;
    }

    &.active {
      background: $gradient;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      &::after {
        content: "";
      }
    }
  }
}

.staking-card {
  padding: 3.5rem;
  text-align: center;
  height: 100%;

  @media (max-width: 1679px) {
    padding: 2.5rem;
  }

  &__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 12rem;
    height: 12rem;
    margin-bottom: 2rem;
    border-radius: 50%;
    background-color: rgba($secondary2, 1);

    @media (max-width: 1679px) {
      width: 8rem;
      height: 8rem;
      margin-bottom: 1.5rem;
    }

    @media (max-width: 767px) {
      width: 6rem;
      height: 6rem;
    }

    svg {
      width: 5rem;
      height: 5rem;
      @media (max-width: 1679px) {
        width: 3.2rem;
        height: 3.2rem;
      }
      @media (max-width: 767px) {
        width: 2rem;
        height: 2rem;
      }
    }
  }
  h2 {
    margin-bottom: 2rem;
    @media (max-width: 1679px) {
      margin-bottom: 1.5rem;
    }
    @media (max-width: 767px) {
      margin-bottom: 1rem;
    }
  }

  h2,
  h5 {
    font-weight: 700;
  }
}

///

.slider {
  padding-bottom: 10px;
  h3 {
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    background-color: #0086ff;
    white-space: nowrap;
    // background: linear-gradient(88.08deg, rgba(1, 124, 201, 0.154) 3.13%, rgba(8, 147, 234, 0.35) 48.43%, rgba(1, 138, 224, 0.173509) 98.38%);
  }
}
