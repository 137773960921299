@import "../../../Assets/Theme/_var.scss";

.custom-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: $white;
  font-size: 2.2rem;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 1rem;
  padding: 1.45rem 3rem;
  background: $btnGradient;
  position: relative;
  box-shadow: 8px 8px 24px 0px rgba(9, 13, 20, 0.4);

  @media (max-width: 1679px) {
    font-size: 1.8rem;
  }

  @media (max-width: 991px) {
    font-size: 1.6rem;
    padding: 1.2rem 2rem;
  }

  &:disabled {
    opacity: 0.5;
  }
}
.headerBtn{
  background: #00A7DC !important;
  border-radius: 10px;
  // max-width: 156px;
  width: 100%;
  height: 50px;
  padding: 13px 15px;
  font-size: 16px;
  color: #020713;
  @media (max-width: 991px) {
   height: 40px;
   font-size: 14px;
  }
}