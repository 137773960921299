@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap");

$baseFont: "Inter", sans-serif;
$baseFont2: "Archivo", sans-serif;

$white: #ffffff;
$black: #000000;
$primary: rgba(37, 57, 239, 1);
$primary2: #f5954e;
$secondary: #feb84d;
$secondary2: #232323;
$secondary3: #a3a3a3;
$secondary4: #727272;
$secondary5: #292c2e;
$borderClr: #262626;
$borderClr2: #252525;

$green: rgba(5, 255, 0, 1);
$blue: #2797d1;
$red: #c3292d;
$orange: #f68630;
$skyblue: #01cde4;
$yellowdark: #fbbb10;
$reddark: #e54c40;

$btnGradient: linear-gradient(
  94.99deg,
  #2539ef 12.78%,
  #00c0cc 101.32%,
  #d11818 113.4%
);

$gradient: linear-gradient(276deg, #feb84d 16.17%, #ef7027 95.08%);
$gradient2: linear-gradient(0deg, rgba(0, 0, 0, 0) -29.92%, #161616 97.38%);
$gradient3: linear-gradient(90deg, #fde076 1.57%, rgba(242, 107, 51, 0) 101.1%);
$gradient4: linear-gradient(261.01deg, #212122 2.84%, rgba(0, 0, 0, 0) 165.67%);

$boxShadow: 0 1.2rem 1.6rem -0.4rem rgba(255, 255, 255, 0.2),
  0 0.4rem 0.6rem -0.2rem rgba(255, 255, 255, 0.15);
$boxShadow2: 0 1rem 1.4rem rgba(255, 255, 255, 0.06);
$boxShadow3: inset 3px 17px 37px rgba(3, 3, 3, 0.6),
  inset 0px 0px 5px rgba(255, 255, 255, 0.1);

// z indexes
$header: 988;
$sidebar: $header + 3;
$sidebar_overlay: $sidebar - 2;
$modal: 1056;
$loader: 1090;
