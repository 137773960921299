@import "../../../Assets/Theme/_var.scss";

.header {
  // padding: 1.2rem 2rem;
  // background: $black;
  padding: 30px 0 1.2rem 0;
  background: transparent;
  position: sticky;
  z-index: 11;
  top: 0;
  transition: background-color 0.3s ease;

  @media (max-width: 767px) {
    // padding: 1rem 1.5rem;
    padding: 0 0 1.5rem 0;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_right {
      display: flex;
      align-items: center;
      gap: 50px;

      p {
        font-size: 15px;
        font-weight: 400;
        white-space: nowrap;

        span {
          font-weight: 600;
        }
      }

      @media (max-width: 991px) {
        display: none;
      }
    }

    img {
      @media (max-width: 991px) {
        max-width: 77px;
      }
    }
  }

  .toggleIcon {
    display: none;
    cursor: pointer;

    @media (max-width: 991px) {
      display: block;
    }
  }
}

.scrollHeader {
  background: black;
}

.offcanvas {
  background-color: #000 !important;
  color: #fff !important;
}

.offcanvas-header {
  padding: 15px 30px !important;
  border-bottom: 1px solid #fff;

  img {
    @media (max-width: 991px) {
      max-width: 77px;
    }
  }
}

.offcanvas-body {
  p {
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: 400;

    span {
      font-weight: 600;
    }
  }
}

.btn-close {
  --bs-btn-close-opacity: 1 !important;
  --bs-btn-close-color: #fff !important;
  --bs-btn-close-hover-opacity: 0.7 !important;
  --bs-btn-close-bg: url('../../../Assets/Icon/close.svg') !important;
}

.inputError {
  // border: solid red 2px !important;
  color: #c3292d;
  font-size: 12px;
}

.contactUs {
  background: #011724;
  .modal-dialog {
    max-width: 600px;
  }
  .modal-content {
    max-width: 600px;
    width: 100%;
    margin: auto;
  }
  .modal-header {
    padding: 20px 20px 0 !important;
  }
  .innerForm {
    border: 0;
    padding: 10px 20px;

    input {
      border: 1px solid #303749;
      background-color: transparent;
      color: #fff;
      padding: 20px;
      border-radius: 5px;
      padding-left: 20px;
      font-size: 14px;

      &::placeholder {
        color: #dddddd96
      }

      &:hover,
      :focus-visible,
      :focus,
      :focus-within {
        background-color: transparent;
      }
    }

    textarea {
      min-height: 120px;
      background-color: transparent;
      border: 1px solid #303749;
      color: #fff;
      border-radius: 5px;
    }

    .modal-footer {
      border-top: 0 !important;
    }

    button {
      max-width: 215px !important;
      width: 100%;
      font-size: 16px;
    }
  }
}

.modal-footer {
  margin-bottom: 20px;
}
.disable{
  opacity: 0.4;
  cursor: not-allowed !important;
}