@import "../../../Assets/Theme/_var.scss";
.rc-pagination {
  &.custom-pagination {
    .rc-pagination-item,
    .rc-pagination-prev,
    .rc-pagination-next {
      margin: 0 1rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 4.2rem;
      height: 4rem;
      border-radius: 0.4rem;
      background: rgba(33, 33, 34, 0.7);
      backdrop-filter: blur(15px);
      border: 0;
      color: $white !important;
      font-size: 1.8rem;
      letter-spacing: -0.18px;
      @media (max-width: 1679px) {
        font-size: 1.6rem;
        width: 3.8rem;
        height: 3.6rem;
      }
      @media (max-width: 767px) {
        font-size: 1.4rem;
        width: 3.2rem;
        height: 3rem;
      }

      &:focus {
        box-shadow: none;
      }

      &:hover {
        background: rgba(33, 33, 34, 1);
      }

      @media (max-width: 1679px) {
        margin: 0 0.5rem;
      }

      &-active {
        background: $gradient !important;
        color: $black !important;
      }
    }

    .rc-pagination-disabled {
      opacity: 0.8;
    }

    .rc-pagination-prev > .rc-pagination-item-link,
    .rc-pagination-next > .rc-pagination-item-link {
      background: transparent;
      border: 0;
    }

    .rc-pagination-jump-next,
    .rc-pagination-jump-prev {
      margin: 0;

      .rc-pagination-item-link {
        color: $white;

        font-weight: 500;
        background: transparent;
      }
    }
  }
}
